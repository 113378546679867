// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lTTbienZIxWUR3MPSA7f{text-align:center}", "",{"version":3,"sources":["webpack://./../next-react/src/components/companion/CompanionLogin/styles.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA","sourcesContent":["$theme: next;\n.CompanionLogin {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CompanionLogin": "lTTbienZIxWUR3MPSA7f"
};
export default ___CSS_LOADER_EXPORT___;
