// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rUWpZaifHMcV8GSYjE2Q{max-width:500px;margin:0 auto;text-align:center}", "",{"version":3,"sources":["webpack://./../next-react/src/components/generic/Choice/styles.scss"],"names":[],"mappings":"AAGA,sBACE,eAAA,CACA,aAAA,CACA,iBAAA","sourcesContent":["$theme: next;\n@import \"../../../scss/variables.scss\";\n\n.Choice {\n  max-width: 500px;\n  margin: 0 auto;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Choice": "rUWpZaifHMcV8GSYjE2Q"
};
export default ___CSS_LOADER_EXPORT___;
