// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fQAgLS8ooOKn6DsLgLau{height:100%}.qc4GrWoMWIs3A0UOl_dN{position:absolute;width:100%;height:100%}.LDNu04fLYysQT3IVylmf{position:absolute;width:100%;height:100%;background-image:linear-gradient(to right, rgb(234, 242, 240) 40%, rgba(234, 242, 240, 0.9))}.B5aCaR8XfS4Jer84Ax8Q{height:100%;position:relative;display:flex;flex-direction:column;overflow-y:scroll}", "",{"version":3,"sources":["webpack://./../next-react/src/components/generic/LocationWelcomePage/styles.scss"],"names":[],"mappings":"AAGA,sBACE,WAAA,CAEA,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CAEF,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,4FAAA,CAMF,sBACE,WAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,iBAAA","sourcesContent":["$theme: next;\n@import \"../../../scss/variables.scss\";\n\n.LocationWelcomePage {\n  height: 100%;\n\n  &_poster {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n  }\n  &_fade {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    background-image: linear-gradient(\n      to right,\n      rgba(234, 242, 240, 1) 40%,\n      rgba(234, 242, 240, 0.9)\n    );\n  }\n  &_container {\n    height: 100%;\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    overflow-y: scroll;\n    // padding: $spacing-lg;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LocationWelcomePage": "fQAgLS8ooOKn6DsLgLau",
	"LocationWelcomePage_poster": "qc4GrWoMWIs3A0UOl_dN",
	"LocationWelcomePage_fade": "LDNu04fLYysQT3IVylmf",
	"LocationWelcomePage_container": "B5aCaR8XfS4Jer84Ax8Q"
};
export default ___CSS_LOADER_EXPORT___;
