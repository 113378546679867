// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".m0dABXjhL8DYwV6EK3iz{height:100%;width:100%;display:flex;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./../next-react/src/components/structure/HeroContent/styles.scss"],"names":[],"mappings":"AACA,sBACE,WAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":["$theme: next;\n.HeroContent {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeroContent": "m0dABXjhL8DYwV6EK3iz"
};
export default ___CSS_LOADER_EXPORT___;
