// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HFjVG4Y95uwrgAyhQvjO{width:280px;margin:0 auto;padding:100px 0}", "",{"version":3,"sources":["webpack://./../next-react/src/components/views/HoldingScreen/styles.scss"],"names":[],"mappings":"AAEE,sBACE,WAAA,CACA,aAAA,CACA,eAAA","sourcesContent":["$theme: next;\n.HoldingScreen {\n  &_logoContainer {\n    width: 280px;\n    margin: 0 auto;\n    padding: 100px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HoldingScreen_logoContainer": "HFjVG4Y95uwrgAyhQvjO"
};
export default ___CSS_LOADER_EXPORT___;
