// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xzm5CCKkY9Mo_LtnODvM{max-width:100px;margin:0 auto}", "",{"version":3,"sources":["webpack://./../next-react/src/components/atoms/ScopeEhrPatientSignupFlow/styles.scss"],"names":[],"mappings":"AAEE,sBACE,eAAA,CACA,aAAA","sourcesContent":["$theme: next;\n.ScopeEhrPatientSignupFlow {\n  &_logo {\n    max-width: 100px;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ScopeEhrPatientSignupFlow_logo": "xzm5CCKkY9Mo_LtnODvM"
};
export default ___CSS_LOADER_EXPORT___;
