// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UBQ09f3aycUujg9jsA4R{display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./../next-react/src/components/structure/Center/styles.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,sBAAA","sourcesContent":["$theme: next;\n.Center {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Center": "UBQ09f3aycUujg9jsA4R"
};
export default ___CSS_LOADER_EXPORT___;
